import { fieldParams, postData, querySearch } from '@utils'


export default {      
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchExpensePeriods() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getExpensePeriods',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchDaysInPeriods(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        postData(querySearch(queryParams, 'getDaysInPeriod'))
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchDaysInPeriods2(ctx, { id }) {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getDaysInPeriod',
          expense_period: `${id}`,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchExpenseTypes() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getExpenseTypes',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
   
    

  },
}
