<template>
    
    <VTextField
      ref="inputRef"   
      :value="formattedValue"
      :v-model="formattedValue"
      hide-details
      dense
      class="text-start"
      color="secondary"      
      @focus="$event.target.select()"
      />
  </template>
   
  <script>
  import useCurrencyInput from 'vue-currency-input'
  import Vue from 'vue'
  import { watch } from '@vue/composition-api'
  
  export default {



    name: 'VCurrencyField',
    props: {
      value: Number,
      options: Object,
      ilkDeger: Boolean
    },
    setup (props) {      
      const {
        inputRef,
        formattedValue,
        setValue
        
      } = useCurrencyInput(props.options)
  
    
      watch(() => props.value, (value) => {
        if(props.ilkDeger){
          setValue(value)
        }
      })
  
      return { inputRef, formattedValue }
    },

  }
  </script>
  